<template>
  <div class="switcher switcher__wrapper">
    <div
      class="switcher__box"
      :class="{
        'switcher__box_anabled': value,
        'switcher_inactive': this.isInactive,
        'switcher_disabled': this.isDisabled,
      }" 
      @click="toggleHandler"
    >    
      <div class="switcher__toggle"/>
      <div class="switcher__text">{{ currentText }}</div>
    </div>
    <Comments
      v-if="isCommentable"
      :uuid="uuid"
      :can-comment="canComment"
      :commentTo="commentTo"
      on-top
    />

  </div>
</template>

<script>
import Comments from '@/elements/Comments.vue';
export default {
  components: {
    Comments
  },
  model: {
    prop: 'value',
    event: 'click',
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: Array, 
      default: () => ['button_yes', 'button_no']
    },
    isCommentable: {
      type: Boolean,
      default: false,
    },
    canComment: {
      type: Boolean,
      default: false,
    },
    commentTo: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      default: '',
    },
    isInactive: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    currentText () {      
      return this.value ? this.$t(`buttons.${this.text[0]}`) : this.$t(`buttons.${this.text[1]}`);
    }
  },

  methods: {
    toggleHandler() {
      if (this.isInactive || this.isDisabled) return;
      this.$emit('click', !this.value);
    }
  }
};
</script>

<style lang="sass" scoped>
.switcher__wrapper
  position: relative
  // border: solid 1px red
  // display: inline-

.switcher__box
  display: inline-flex
  align-items: center
  height: 42px
  width: 82px
  padding: 5px
  border-radius: 50px
  background-color: #C9C9C9
  color: $white
  font-size: 16px
  cursor: pointer
  position: relative

.switcher__box_anabled
  background-color: #84B74D
  .switcher__toggle
    margin-left: calc(100% - 35px)
    transition: all .2s ease

  .switcher__text
    left: 10px
    font-size: 12px

.switcher__toggle
  width: 34px
  height: 34px
  background-color: $white
  border-radius: 50%
  transition: all .2s ease

.switcher__text
  position: absolute
  right: 10px
  font-size: 12px

.switcher_inactive, .switcher_disabled
  cursor: default

.switcher_disabled
  opacity: 0.7
</style>
